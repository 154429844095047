:root {
    --primary-color: #6E12F9;
    --black-color: #000;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 8px;
    border-bottom: 1px solid var(--black-color);
}

.tableHeaderMsg {
    text-align: left !important;
    font-size: 16px;
    font-weight: 700;
    margin-top: -25px;
    text-align: center;
    text-transform: uppercase;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.pagination button {
    outline: none;
    border: 1px solid #f3f6f9;
    background-color: #fff;
    font-size: 14px;
    border-radius: 5px;
}

.pagination .gotoPage {
    width: 35%;
}

.pagination .selectDisplayNoOfRecords {
    border-radius: 10px;
    padding: 2px 20px;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.dashboardTable thead tr th {
    /* background-color: var(--primary-color); */
    color: #fff;
    padding: 10px;
    border-top: 1px solid var(--black-color);
    border-left: 1px solid var(--black-color);
    background-image: linear-gradient(#f7674c,#f47746);
    border-bottom: 1px solid transparent;
}
.orderTableFooter tr td{
    padding: 10px;
    border-top: 1px solid var(--black-color);
    border-left: 1px solid var(--black-color);
    border-bottom: 1px solid var(--black-color);
}
.orderTableFooter tr td:last-child{
    border-right: 1px solid var(--black-color);
}
.orderTableFooter .tblFooterColor{
    font-weight: 600;
    font-size: 17px;
}

.dashboardTable thead tr th:last-child {
    border-right: 1px solid var(--black-color);
    min-width: 8vw;
}

.dashboardTable tbody tr td {
    padding: 8px;
    border-top: 1px solid var(--black-color);
    border-left: 1px solid var(--black-color);
}

.dashboardTable tbody tr td {
    border-right: 1px solid var(--black-color);
}

.dashboardTable tbody tr:last-child td {
    border-bottom: 1px solid var(--black-color);
}

.dashboardTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.dashboardTable tr:hover {
    background-color: #e4e4e7;
}

.loanTblDownloadLoanIdBtn:focus {
    box-shadow: none;
}

.loanTblDownloadLoanIdBtn {
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    padding: 6px 2px;
    border-radius: 50px;
}

.someprobtn:hover {
    background-color: #fff;
    color: #000;
    font-weight: 600;
    border: 1px solid #ccc;
}

#accordionPanelsStayOpenExample .accordion-button:not(.collapsed) {
    background-color: #333333;
    box-shadow: none;
    color: #fff;
    font-weight: 600;
}

#accordionPanelsStayOpenExample .accordion-item {
    background-color: #fff;
    border: 1px solid #000 !important;
    margin-top: 30px;
}

.accordion-button:focus {
    border: none;
    box-shadow: none;
}

.accordion-button {
    font-weight: 600;
}

.merchantdesignlay {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(113,62,115,1) 0%, rgb(10 60 71) 100%);
    padding-top: 25px;
    margin-bottom: 30px;
}

.merchantdesignlay .merchantNameDetails {
    text-align: center;
}

.merchantdesignlay .merchantNameDetails h5 {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
}

.merchantdesignlay .merchantNameDetails p {
    font-size: 14px;
    font-weight: 600;
    color: #e4e4e4;
}

.bankDetailsMerchant .merchantNameDetails {
    text-align: center;
}

.bankDetailsMerchant .merchantNameDetails h5 {
    font-size: 15px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
}

.bankDetailsMerchant .merchantNameDetails p {
    font-size: 14px;
    font-weight: 600;
    color: #948f8f;
}

.businessMerchantDetails table thead tr th {
    background-color: #8045da;
    color: #fff;
    padding: 10px;
    border-top: 1px solid var(--black-color);
    border-left: 1px solid var(--black-color);
    font-size: 14px;
}

.businessMerchantDetails table thead tr th:last-child {
    border-right: 1px solid var(--black-color);
}

.businessMerchantDetails table tbody tr td {
    padding: 10px;
    border-top: 1px solid var(--black-color);
    border-left: 1px solid var(--black-color);
    font-weight: 600;
}

.businessMerchantDetails table tbody tr td {
    border-right: 1px solid var(--black-color);
}

.businessMerchantDetails table tbody tr:last-child td {
    border-bottom: 1px solid var(--black-color);
}

.businessMerchantDetails table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.businessMerchantDetails table tr:hover {
    background-color: #e4e4e7;
}
.businessMerchantDetails table {
 width: 100%;
}
.businessMerchantDetails table:nth-child(2){
    margin-top: 30px;
}

.bankTableDetailsData table tr th {
    color: #000;
    padding: 10px;
    border-top: 1px solid var(--black-color);
    border-left: 1px solid var(--black-color);
    font-size: 14px;
}

.bankTableDetailsData table tr th:last-child {
    border-right: 1px solid var(--black-color);
}

.bankTableDetailsData table  tr td {
    padding: 10px;
    border-top: 1px solid var(--black-color);
    border-left: 1px solid var(--black-color);
    font-weight: 600;
}

.bankTableDetailsData table  tr td {
    border-right: 1px solid var(--black-color);
}

.bankTableDetailsData table  tr:last-child td {
    border-bottom: 1px solid var(--black-color);
}
.bankTableDetailsData table  tr:last-child th {
    border-bottom: 1px solid var(--black-color);
}
.bankTableDetailsData table tr:nth-child(even) {
    background-color: #f2f2f2;
}
/* .bankTableDetailsData .derog table tr:nth-child(odd){
    background-color: #f2f2f2;
} */
.bankTableDetailsData table{
    width: 100%;
}
.table-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 10px;
  }
  
  .table-wrapper {
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
  .data-table th, .data-table td {
    padding: 10px 15px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .data-table th {
    /* background-color: #007bff; */
    color: black;
    width: 150px; 
  }
  
  .data-table tr:first-child {
    background-color: #f2f2f2;
  }
  
  .data-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  
.orderListDataFooter .dashboardTable .orderTableFooter{
    display: none;
}
.activeStatus{
    color: #50e150 !important;
  }
  .SuspendedStatus{
    color:red !important;
  }
  .defaluStatus{
    color: black !important;
  }
  .creditReportMainSection .bankTableDetailsData .tableRowAdjustCustom tr th{
   width: 25%;
  }

  .formbold-browse {
    font-weight: 500;
    font-size: 16px;
    color: #07074d;
    display: inline-block;
    padding: 8px 28px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  .maincontainer{
    height: auto;
    padding: 20px;
    border: 1px solid black;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
  }
.creditTitle{
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: #575353;
    font-size: 1.5rem;
    font-family: sans-serif;
    margin-top: -50px;
}
.personalInfo{
    background-color: #8e191b;
    margin-left: 30px;
    color: white;
    margin-right: 30px;
    text-align: center;
    font-weight: 600;
}
.consumerAddress{
  margin: 30px;
  color: #575353; 
  margin-top: -5px;
}
.container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  
  .left-side,
  .right-side {
    display: flex;
    flex-direction: column;
    color:#8e191b;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-left: 1px;
    font-style: italic;
  }
  
  .left-side {
    flex: 1; 
    
  }
  
  .right-side {
    flex: 0.2;
  }
  .headingwithLogo{
    display: contents;
    justify-content: center;
    align-items: center;
  }
  .personInfoData{
    display: flex;
    justify-content: space-between; 
  }
  .columnData{
    flex: 1; 
    margin: 0 10px;
    padding: 10px;
    margin-left: 100px;
    font-style: italic;
    font-size: 13px;
    font-weight: 450;
  }
  .columnData p {
    margin-bottom: 10px;
  }
  .consumerAddTable tbody{
    background-color: white !important;
    color: #575353; 
    border: 1px solid black;
    font-style: italic;
    font-size: 13px;
    font-weight: 450;
  }
  .consumerAddTable td, th{
    border: 1px solid black;
  }
  .recentAct{
    border: none !important;
    font-style: italic;
    font-size: 13px;
    font-weight: 450;
    margin-left: 10px;
}
.consumerAddTable td{
    text-align: left;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.recentActInqury{
    text-align: center !important;
    padding-top: 1px;
    padding-bottom: 1px;
    border: none !important;
}